
export default {
  name: "FAQS",
  data() {
    return {
      faqs: [
        {
          title: this.$t("faqNeedTechExperienceTitle"),
          content: this.$t("faqNeedTechExperienceContent"),
        },
        {
          title: this.$t("faqServicesInPackagesTitle"),
          content: this.$t("faqServicesInPackagesContent"),
        },
        {
          title: this.$t("faqIncludesUpdatesTitle"),
          content: this.$t("faqIncludesUpdatesContent"),
        },
        {
          title: this.$t("faqCancelServiceTitle"),
          content: this.$t("faqCancelServiceContent"),
        },
        {
          title: this.$t("faqCreateStoreQuicklyTitle"),
          content: this.$t("faqCreateStoreQuicklyContent"),
        },
      ],
    };
  },
};
